<!--
 * @Author: your name
 * @Date: 2021-11-20 09:23:51
 * @LastEditTime: 2021-12-09 08:37:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /vue-project/src/views/home/project/inc-header.vue
-->
<template>
  <v-app style="height: 40px" class="grey lighten-5">
    <v-container
      class="pa-0 grey lighten-4"
      style="max-width: 100%; width: 100%;position: sticky; z-index: 10; top: 0px"
    >
      <v-card class="ma-0" elevation="1" tile color="primary" id="menu">
        <v-card-title primary-title class="py-2 pl-2 white--text">
          <v-btn fab class="mr-3" style="width: 30px; height: 30px">
            <img src="./images/logo-96.png" style="width: 30px" alt="" />
          </v-btn>
          学校管理平台
          <v-spacer></v-spacer>
          <v-btn
            v-if="$route.path != '/'"
            color="white"
            class="primary--text px-2 mr-2"
            small
            @click="$router.push({ path: '/' })"
          >
            <v-icon>mdi-home</v-icon>
            <span>首页</span>
          </v-btn>
          <v-btn v-if="!$sopen.isWechat()" color="white" class="error--text px-2" small @click="openOut()">
            退出
            <v-icon class="ml-1">mdi-login-variant</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogLogin" max-width="390">
      <v-card>
        <v-card-title class="text-h6 pl-3">
          <v-icon class="pr-4" color="error">mdi-alert</v-icon>
          系统提示
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="font-size: 18px; min-height: 100px" class="pa-5"
          >你确认要退出社区吗？
        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0" @click="dialogLogin = false" class="mr-4">
            <v-icon class="pr-3">mdi-close</v-icon>
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="error" @click="loginout">
            <v-icon class="pr-3">mdi-check</v-icon>
            确认退出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
require("./images/logo-96.png");
export default {
  data() {
    return {
      dialogLogin: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    openOut() {
      this.dialogLogin = true;
      return false;
    },
    loginout() {
      this.$sopen.loginout();
    },
  },
};
</script>
